
/* eslint-disable camelcase */
import Component, {mixins} from 'vue-class-component';
import {Emit, Watch} from 'vue-property-decorator';

import {userStore} from '@/store/__STORE_user';
import {mainStore} from '@/store/__STORE_main';
import {authStore} from '@/store/__STORE_auth';

import CallGroupEmails from '@/components_v2/generic/form/__COMP_CallGroupEmails.vue';
import CallGroupAutoDialProtocol from '@/components_v2/generic/form/__COMP_CallGroupAutoDialProtocol.vue';
import CallGroupAutoDialAddress from '@/components_v2/generic/form/__COMP_CallGroupAutoDialAddress.vue';
import PasswordStrength from '@/components/PasswordStrength.vue';

import Plan from '@/mixins/Plan.ts';
import PasswordStrengthVars from '@/mixins/PasswordStrengthVars.ts';
import {ContentWindow, Card} from '@/components/Styling';

type Errors = {
  alias?: Object,
  name?: Object,
  last_name?: Object,
  phone?: Object,
  dial_address?: Object;
  call_group_address?: Object,
  call_group_addresses?: Object,
}

// Should be moved to Premium Account when that file is converted to Typescript
type PremiumPopup = {
  headerText?: String,
  mainText?: String,
  buttonText?: String,
  hideButton?: Boolean,
  prevRoute?: String,
  showPremium?: Boolean,
}

@Component({
  components: {
    PasswordStrength,
    CallGroupEmails,
    CallGroupAutoDialProtocol,
    CallGroupAutoDialAddress,
    ContentWindow,
    Card,
  },
})

/**
 * Intelligent component for Editing Profile, component allows for the editing of a user profile
 */
export default class Profile extends mixins(Plan, PasswordStrengthVars) {
  name: 'Edit Profile';

  isModalShow: Boolean = false;

  existingRoomId: string = '';
  newRoomId: string = '';

  existingName: string = '';
  newName: string = '';
  existingLastName: string = '';
  newLastName: string = '';
  existingPhone: string = '';
  newPhone: string = '';

  existingEnableCallGroup: Boolean = false;
  newEnableCallGroup: Boolean = false;
  existingEnableDialOut: Boolean = false;
  newEnableDialOut: Boolean = false;
  existingAutoDialProtocol: string = '';
  newAutoDialProtocol: string = '';
  existingAutoDialAddress: string = '';
  newAutoDialAddress: string = '';
  existingEmailGroup: Array<any> = [];
  newEmailGroup: Array<any> = [];

  existingEmail: string = '';
  newEmail: string = '';
  emailError: Boolean = false;
  emailSameError: Boolean = false;
  isEmailVerified: Boolean = true;

  existingPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  passwordError: Boolean = false;

  errors: Errors = {};
  personalRoomIdInputStyle: any = {};

  // eslint-disable-next-line
  reg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/);

  /**
   * Gets the window location origin
   */
  get isLoading() {
    return mainStore.__GETTERstatus !== 'success';
  }

  /**
   * Gets the window location origin
   */
  get origin() {
    return window.location.origin;
  }
  /**
   * Gets personal url
   */
  get personalUrl() {
    if (!this.userInfo || !this.userInfo.meeting_room) {
      return this.origin;
    }

    return this.origin + '/' + this.userInfo.meeting_room.webrtc.alias;
  }
  /**
   * Gets profileData from the userStore
   */
  get userInfo() {
    return userStore.__GETTERuserProfile;
  }
  /**
   * Gets protectedRoutes from the mainStore
   */
  get protectedRoutes() {
    return mainStore.protectedRoutes;
  }

  /**
   * Has the room id changed
   */
  get roomIDChanged(): Boolean {
    return this.newRoomId !== this.existingRoomId && this.newRoomId !== '';
  }

  /**
   * Has the profile details changed
   */
  get detailsChanged(): Boolean {
    return this.newName !== this.existingName || this.newLastName !== this.existingLastName || this.newPhone !== this.existingPhone;
  }

  /**
   * Has the profile details changed
   */
  get callGroupChanged(): Boolean {
    return this.newEnableCallGroup !== this.existingEnableCallGroup ||
    this.newEnableDialOut !== this.existingEnableDialOut ||
    this.newAutoDialProtocol !== this.existingAutoDialProtocol ||
    this.newAutoDialAddress !== this.existingAutoDialAddress ||
    this.anyNewEmails;
  }

  /**
   * Has the email array changed
   */
  get anyNewEmails(): any {
    return !this.newEmailGroup.every((val, index) => val === this.existingEmailGroup[index]);
  }

  /**
   * Has the profile details changed
   */
  get sameEmail(): Boolean {
    return this.newEmail === this.existingEmail;
  }

  /**
   * Has the profile details changed
   */
  get hasNewEmail(): Boolean {
    return this.reg.test(this.newEmail) && !this.sameEmail;
  }

  /**
   * Has the profile details changed
   */
  get emailChanged(): Boolean {
    return !this.sameEmail && this.confirmPassword !== '';
  }

  /**
   * Has the profile details changed
   */
  get samePassword(): Boolean {
    return this.newPassword != '' && this.newPassword === this.existingPassword;
  }

  /**
   * Has the profile details changed
   */
  get passwordChanged(): Boolean {
    return this.newPassword != '' && !this.samePassword && !this.__MIXINshowPasswordStrengthError;
  }

  /**
   * Gets protectedRoutes from the mainStore
   * @param {any} val - update UserInfo
   */
  @Watch('userInfo', {deep: true})
  refreshUserInfo() {
    if (this.userInfo) {
      if (this.userInfo.meeting_room && this.userInfo.meeting_room.webrtc && this.userInfo.meeting_room.webrtc.alias) {
        this.newRoomId = this.existingRoomId = this.userInfo.meeting_room.webrtc.alias ? this.userInfo.meeting_room.webrtc.alias : '';
      }

      this.newName = this.existingName = this.userInfo.name ? this.userInfo.name : '';
      this.newLastName = this.existingLastName = this.userInfo.last_name ? this.userInfo.last_name : '';
      this.newPhone = this.existingPhone = this.userInfo.phone ? this.userInfo.phone : '';


      if (this.__MIXINuserCanAccessHailMary) {
        this.newEnableCallGroup = this.existingEnableCallGroup = this.userInfo.call_group.enable_call_group ? this.userInfo.call_group.enable_call_group : false;
        this.newEnableDialOut = this.existingEnableDialOut = this.userInfo.call_group.enable_dial_out ? this.userInfo.call_group.enable_dial_out : false;
        this.newAutoDialProtocol = this.existingAutoDialProtocol = this.userInfo.call_group.auto_dial.protocol ? this.userInfo.call_group.auto_dial.protocol : '';
        this.newAutoDialAddress = this.existingAutoDialAddress = this.userInfo.call_group.auto_dial.address ? this.userInfo.call_group.auto_dial.address : '';
        this.existingEmailGroup = this.userInfo.call_group.email_group ? this.userInfo.call_group.email_group : [];
        this.newEmailGroup = [...this.existingEmailGroup];
      }

      this.existingEmail = this.userInfo.email ? this.userInfo.email : '';
    }
  }


  /**
   *
   */
  mounted() {
    this.refreshUserInfo();
  }

  /**
   * Show the Premium Feature Popup
  */
  @Emit('show-premium-feature-popup')
  __EMITshowAlert(): void {}

  /**
   * Show XR Premium Feature Popup
   * Fires for setting or clearing branding
   * @return {PremiumPopup} xr - the brand to be set | null
   */
  @Emit('show-premium-feature-popup')
  __EMITshowXRAlert() {
    const xr: PremiumPopup = {
      headerText: 'You have found the \'Hail Mary\' feature!',
      mainText: 'This feature requires the \'XR Gateway\' addon, purchase it to unlock these settings.',
      buttonText: 'Buy XR Gateway Addon',
    };
    return xr;
  }

  /**
   * @param {string} value - v-model string
   */
  __EVENTupdateDialAddress(value: string): void {
    this.newAutoDialAddress = value;
  }

  /**
   * @param {string} value - v-model string
   */
  __EVENTupdateDialProtocol(value: string): void {
    this.newAutoDialProtocol = value;
  }

  /**
   * Update Profile Details
   */
  __EVENTupdateDetails(): void {
    const param = {
      name: this.newName,
      last_name: this.newLastName,
      phone: this.newPhone,
    };
    userStore.updateProfile(param)
        .then(() => {
          this.errors = {};
          this.$toast.success('Updated successfully!');
        })
        .catch((err) => {
          const data = err.response.data;
          this.$toast.error(data.message);
          if (err.response.status === 422) {
            this.errors = data.errors;
          }
        });
  }

  /**
   * Update the password
   */
  __EVENTupdatePassword(): void {
    if (this.newPassword && !this.__MIXINsufficientPassword) {
      this.errors = {};
      this.__MIXINupdatePasswordStrength(true);
      return;
    }

    const param = {
      old_password: this.existingPassword,
      new_password: this.newPassword,
    };
    userStore.updatePassword(param)
        .then(() => {
          this.$toast.success('Updated successfully! You will now be logged out.');
          this.existingPassword = '';
          this.newPassword = '';
          this.__MIXINupdatePasswordStrength(false);
          this.errors = {};
          authStore.logout().then(() => {
            this.$router.push('/login');
          });
        })
        .catch(() => {
          this.$toast.error('Password incorrect.');
          this.errors = {};
          this.existingPassword = '';
          this.newPassword = '';
        });
  }

  /**
   * Update the password
   */
  __EVENTupdateEmail():void {
    this.passwordError = false;
    this.emailError = false;
    this.emailSameError = false;
    if (this.newEmail == this.existingEmail) {
      this.emailSameError = true;
      return;
    }
    if (!this.reg.test(this.newEmail)) {
      this.emailError = true;
      return;
    }
    if (this.confirmPassword == '') {
      this.passwordError = true;
      return;
    }

    const param = {
      old_email: this.existingEmail,
      new_email: this.newEmail,
      password: this.confirmPassword,
    };

    userStore.updateEmail(param)
        .then(() => {
          this.$toast.success('Updated successfully! Please go and verify your new email address.');
          this.newEmail = '';
          this.confirmPassword = '';
          this.isEmailVerified = false;
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.$toast.error('Email already in use by another user.');
          } else if (err.response.status == 403) {
            this.$toast.error('Password incorrect.');
          } else {
            this.$toast.error('Something went wrong.');
          }
        });
  }

  /**
   * Update the room ID
   */
  __EVENTupdateRoomId(): void {
    this.newRoomId = this.newRoomId.replaceAll(/\s/g, '');
    const param = {
      alias: this.newRoomId,
    };
    userStore.updateRoomId(param)
        .then(() => {
          this.$toast.success('The room id was updated successfully!');
          this.errors = {};
        })
        .catch((err) => {
          try {
            const data = err.response.data;
            this.$toast.error(data.message);
            if (err.response.status === 422) {
              this.errors = data.errors;
            }
          } catch {
            alert(err);
          }
        });
  }

  /**
   * Update the room ID
   */
  __EVENTupdateCallGroup(): void {
    const emails = this.newEmailGroup.filter((item) => {
      if (item != null || item != '') {
        return item;
      }
    });
    const error = this.checkEmails(emails);
    if (!error) {
      const param = {
        emails: emails,
        protocol: this.newAutoDialProtocol,
        dialAddress: this.newAutoDialAddress,
        enable_call_group: this.newEnableCallGroup,
        enable_auto_dial_out: this.newEnableDialOut,
      };
      userStore.updateCallGroupSettings(param)
          .then(() => {
            this.$toast.success('The call group settings were updated!');
            this.errors = {};
          })
          .catch((err) => {
            try {
              const data = err.response.data;
              this.$toast.error(data.message);
              if (err.response.status === 422) {
                this.errors = data.errors;
              }
            } catch {
              this.$toast.error(err);
            }
          });
    } else {
      this.$toast.error('One or more email addresses incorrect, please fix and try again.');
    }
  }
  /**
   * Check the email is valid
   */
  __EVENTcheckValidEmail(): void {
    this.emailError = !this.reg.test(this.newEmail);
  }
  /**
   * Check an array of emails are valid
   * @param {Array<string>} emails - array of emails
   * @return {Boolean} whether all the emails are valid
   */
  checkEmails(emails: Array<string>): Boolean {
    for (let i = 0; i < emails.length; i++) {
      if (!this.reg.test(emails[i])) {
        return true;
      }
    }
    return false;
  }
}
