
import Vue from 'vue';
import {Component, PropSync} from 'vue-property-decorator';

export type TErrors = {
  [key: number]: boolean
}

@Component
/**
 * Basic form that obtains infromation for outbound streaming
 */
export default class CallGroupEmails extends Vue {
  name = 'CallGroupEmails';

  @PropSync('editing', {type: Boolean, default: false, required: true}) readonly __PROPediting: string // is the component editable
  @PropSync('value', {type: Array, required: true}) __PROPvalue: Array<string> // value array of email addresses

  errors: TErrors = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  }
  // eslint-disable-next-line
  reg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/);

  /**
  * Checks emails to see if they are valid and updates errors object
  * @param {string} value - email string
  * @param {number} index - field index
  */
  checkEmail(value, index): void {
    switch (index) {
      case 0:
        this.errors['0'] = !this.reg.test(value) && value != '';
        break;
      case 1:
        this.errors['1'] = !this.reg.test(value) && value != '';
        break;
      case 2:
        this.errors['2'] = !this.reg.test(value) && value != '';
        break;
      case 3:
        this.errors['3'] = !this.reg.test(value) && value != '';
        break;
      case 4:
        this.errors['4'] = !this.reg.test(value) && value != '';
        break;
    }
  }
}
