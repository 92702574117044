
import Vue from 'vue';
import {Component, PropSync, Emit} from 'vue-property-decorator';

@Component
/**
 * Basic form input for address
 */
export default class CallGroupAutoDialAddress extends Vue {
  name = 'CallGroupAutoDialAddress';

  @PropSync('editing', {type: Boolean, default: false, required: true}) readonly __PROPediting: Boolean // alias of the VMR to be queried
  @PropSync('value', {type: String, required: true}) readonly __PROPvalue: Boolean

  /**
   * @param {string} value
   * @return {string}
   */
  @Emit('update')
  updateValue(value): string {
    return value;
  }
}
