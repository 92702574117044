
import Vue from 'vue';
import {Component, PropSync, Emit} from 'vue-property-decorator';

import Multiselect from 'vue-multiselect';

@Component({
  components: {
    Multiselect,
  },
})
/**
 * Multiselect form input for auto dial protocols
 */
export default class CallGroupAutoDialProtocol extends Vue {
  name = 'CallGroupAutoDialProtocol';

  protocolOptions: Array<string> = ['auto', 'sip', 'h323', 'mssip'];

  @PropSync('editing', {type: Boolean, default: false, required: true}) readonly __PROPediting: Boolean // alias of the VMR to be queried
  @PropSync('value', {type: String, required: true}) readonly __PROPvalue: Boolean

  /**
   * @param {string} value
   * @return {string}
   */
  @Emit('update')
  updateValue(value): string {
    return value;
  }
}
