
/* eslint-disable camelcase */
import Component, {mixins} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';

import {userStore} from '@/store/__STORE_user';
import {meetingStore} from '@/store/__STORE_meetings';
import Plan from '@/mixins/Plan';

import Multiselect from 'vue-multiselect';
import ConfirmSelfDeleteDialog from '@/components/ConfirmSelfDeleteDialog.vue';
import Profile from '@/components_v2/generic/form/__COMP_Profile.vue';
import ContentWindow from '@/components_v2/generic/styling/ContentWindow.vue';
import Card from '@/components_v2/generic/styling/Card.vue';

import {ITimezone} from '@/views_v2/Meetings/__VIEW_CreateMeeting.vue';

@Component({
  components: {
    ConfirmSelfDeleteDialog,
    Multiselect,
    Profile,
    ContentWindow,
    Card,
  },
})
/**
 * Intelligent view for the editing of a user's meeting alias, timezone and notifications settings
 */
export default class Settings extends mixins(Plan) {
  name: 'Settings';

  existingPin: string = '';
  newPin: string = '';
  existingGuestPin: string = '';
  newGuestPin: string = '';

  timezones: Array<ITimezone> = [];
  existingTimezone: ITimezone = {
    id: '',
    name: '',
  };
  newTimezone: ITimezone = {
    id: '',
    name: '',
  };

  existingEmailNotifications: Boolean = false;
  newEmailNotifications: Boolean = false;

  existingLockRoomsByDefault: Boolean = false;
  newLockRoomsByDefault: Boolean = false;

  existingChatTranscripts: Boolean = false;
  newChatTranscripts: Boolean = false;

  existingNotifyHostBeforeMeetingStarts: number = 0;
  newNotifyHostBeforeMeetingStarts: number = 0;
  existingNotifyGuestsBeforeMeetingStarts: number = 0;
  existingNotifyHostWhenGuestEntersRoom: number = 0;

  plugin_password: string = '';
  confirm_plugin_password: string = '';
  isShowDeleteAccount: Boolean = false;

  /**
   * Gets the window location origin
   */
  get settings() {
    return userStore.__GETTERuserSettings;
  }

  /**
   * Has the timezone changed
   */
  get meetingLockChanged(): Boolean {
    return this.newPin !== this.existingPin || this.newGuestPin !== this.existingGuestPin || this.newLockRoomsByDefault !== this.existingLockRoomsByDefault || this.newChatTranscripts !== this.existingChatTranscripts;
  }

  /**
   * Has the timezone changed
   */
  get emailNotificationsChanged(): Boolean {
    return this.newNotifyHostBeforeMeetingStarts !== this.existingNotifyHostBeforeMeetingStarts;
  }

  /**
   * Has the timezone changed
   */
  get timezoneChanged(): Boolean {
    return this.newTimezone && this.existingTimezone && this.newTimezone.id !== this.existingTimezone.id;
  }

  /**
   * Gives context aware text for the v-tooltip for toggling transcripts
   * @return {string}
   */
  get transcriptTooltip(): string {
    if (this.__MIXINhasActiveStorageAddon) {
      return 'The transcript includes details of the participants, the messages sent and received, and any files shared.';
    }
    return '';
  }

  /**
   * Gets protectedRoutes from the mainStore
   * @param {any} val - update UserInfo
   */
  @Watch('settings', {deep: true})
  WatchSettings() {
    this.initSettings();
  }

  /**
   * Get timezones array and initialise settings on view creation
   */
  created() {
    userStore.getUserSettings();
    meetingStore.getTimezones().then((response) => {
      this.timezones = response.data.map((timezoneData) => {
        const gmt = ' GMT' + (timezoneData.offset >= 0 ? '+' : '') + timezoneData.offset;
        return {
          id: timezoneData.identifier,
          name: timezoneData.identifier + gmt,
        };
      });
      this.initSettings();
    });
  }

  /**
   * Initial each setting
   */
  initSettings(): void {
    this.initMeetingSettings();
    this.initTimezoneSettings();
    this.initEmailSettings();
    this.initPasswordSettings();
  }

  /**
   *
   */
  mounted() {
    this.initSettings();
  }

  /**
   * Initial Meeting Settings
   */
  initMeetingSettings(): void {
    if (this.settings && this.settings.length > 0) {
      this.newPin = this.existingPin = this.settings[Object.keys(this.settings).find((key) => this.settings[key].key === 'pin')].value;
      this.newGuestPin = this.existingGuestPin = this.settings[Object.keys(this.settings).find((key) => this.settings[key].key === 'guest_pin')].value;
      this.newLockRoomsByDefault = this.existingLockRoomsByDefault = Boolean(this.settings[Object.keys(this.settings).find((key) => this.settings[key].key === 'lock_rooms_by_default')].value);
      this.newChatTranscripts = this.existingChatTranscripts = Boolean(this.settings[Object.keys(this.settings).find((key) => this.settings[key].key === 'enable_chat_transcripts')].value);
    }
  }

  /**
   * Initial Timezone Settings
   */
  initTimezoneSettings(): void {
    let timezoneObj: ITimezone = null;
    if (
      this.settings &&
      this.settings.length > 0 &&
      this.timezones &&
      this.timezones.length > 0
    ) {
      const timezoneName = this.settings[
          Object.keys(this.settings).find(
              (key) => this.settings[key].key === 'timezone',
          )
      ].value;

      // Retrieve the timezone object from the timezones array
      if (timezoneName.length > 0) {
        timezoneObj = this.timezones[
            Object.keys(this.timezones).find(
                (key) => this.timezones[key].id === timezoneName,
            )
        ];
      }
    }

    this.newTimezone = this.existingTimezone = timezoneObj;
  }

  /**
   * Initial Email Settings
   */
  initEmailSettings(): void {
    if (this.settings && this.settings.length > 0) {
      this.newNotifyHostBeforeMeetingStarts = this.existingNotifyHostBeforeMeetingStarts = this.settings[Object.keys(this.settings).find((key) => this.settings[key].key === 'notify_host_before_meeting_starts')].value;
      this.existingNotifyGuestsBeforeMeetingStarts = this.settings[Object.keys(this.settings).find((key) => this.settings[key].key === 'notify_guests_before_meeting_starts')].value;
      this.existingNotifyHostWhenGuestEntersRoom = this.settings[Object.keys(this.settings).find((key) => this.settings[key].key === 'notify_host_when_guest_enters_room')].value;
    }
  }

  /**
   * Initial Password Settings
   */
  initPasswordSettings(): void {
    this.plugin_password = '',
    this.confirm_plugin_password = '';
  }

  /**
   * Update Meeting Settings
   */
  __EVENTupdateMeetingSettings(): void {
    const param = {
      pin: this.newPin,
      guest_pin: this.newGuestPin,
      lock_rooms_by_default: Number(this.newLockRoomsByDefault),
      enable_chat_transcripts: Number(this.newChatTranscripts),
    };
    userStore.updateMeetingSettings(param)
        .then(() => {
          this.$toast.success('Settings updated successfully!');
        })
        .catch((err) => {
          try {
            const data= err.response.data;
            let messages: any = {};
            this.$toast.error(data.message);
            if (err.response.status === 422) {
              for (messages of Object.values(data.errors)) {
                for (const msg of messages) {
                  this.$toast.error(msg);
                }
              }
            }
          } catch {
            this.$toast.error(err);
          }
        });
  }

  /**
   * Update Timezone Settings
   */
  __EVENTupdateTimezoneSettings() {
    const param = {
      timezone: this.newTimezone.id,
    };
    userStore.updateTimezoneSettings(param)
        .then(() => {
          this.$toast.success('Timezone updated successfully!');
        })
        .catch((err) => {
          this.$toast.error('Error');
        });
  }

  /**
   * Update Email Settings
   */
  __EVENTupdateEmailSettings() {
    const param = {
      notify_host_before_meeting_starts: this.newNotifyHostBeforeMeetingStarts,
      notify_guests_before_meeting_starts: this.existingNotifyGuestsBeforeMeetingStarts,
      notify_host_when_guest_enters_room: this.existingNotifyHostWhenGuestEntersRoom,
    };
    userStore.updateEmailSettings(param)
        .then(() => {
          this.$toast.success('Updated!');
        })
        .catch((err) => {
          try {
            const data= err.response.data;
            let messages: any = {};
            this.$toast.error(data.message);
            if (err.response.status === 422) {
              for (messages of Object.values(data.errors)) {
                for (const msg of messages) {
                  this.$toast.error(msg);
                }
              }
            }
          } catch {
            this.$toast.error(err);
          }
        });
  }
}
