<template>
  <div
    v-if="!isLoading"
    id="modal-calendar-integration"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content">
          <div
            class="f-modal-close"
            @click="$emit('cancel')"
          />
          <div class="modal-popup">
            <div class="modal-popup-content">
              <confirm-dialog-icon />

              <h6 class="mb-2">
                Confirm delete
              </h6>

              <div v-if="canSelfDelete">
                Are you sure you want to delete your account? This cannot be reversed.
                Please confirm your decision with your password.

                <div
                  class="form-group mt-1 mb-2"
                  :class="{'form-error': errors.password}"
                >
                  <input
                    id="password-confirm"
                    v-model="password"
                    class="form-control"
                    type="password"
                    placeholder="Your password..."
                  >
                  <div
                    v-for="(error, index) in errors.password"
                    :key="index"
                    class="form-error-message"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="isLastAdmin"
                  class="alert alert-warning"
                  role="alert"
                >
                  You are the only admin in this account. Before you leave, you must promote
                  at least one other user to admin!
                </div>
                <div
                  v-else-if="!canSelfDelete"
                  class="alert alert-warning"
                  role="alert"
                >
                  You cannot delete your account.
                </div>
              </div>

              <!--
                            <div v-show="isPaymentMethodOwner" class="alert alert-warning" role="alert">
                                Note that you are the payment method owner in this account.
                                After you leave, this payment method will be deleted and someone else will have to update it.
                            </div>
                            -->
            </div>

            <div class="text-center">
              <a
                href="#"
                class="btn btn-outline"
                @click.prevent="$emit('cancel')"
              >Cancel</a>
              <a
                v-if="canSelfDelete"
                href="#"
                class="btn btn-primary ml-2"
                @click.prevent="deleteAccount"
              >Delete account</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {mainStore} from '@/store/__STORE_main';
import {authStore} from '@/store/__STORE_auth';
import ConfirmDialogIcon from './ConfirmDialogIcon';

export default {
  components: {
    ConfirmDialogIcon,
  },
  props: {},
  data() {
    return {
      isLoading: true,
      canSelfDelete: false,
      isPaymentMethodOwner: false,
      isLastAdmin: false,
      password: '',
      errors: {},
    };
  },
  created() {
    // eslint-disable-next-line
        $('body').addClass('f-modal-is-open');
    this.verifyCanDelete();
  },
  destroyed() {
    // eslint-disable-next-line
        $('body').removeClass('f-modal-is-open');
  },
  methods: {
    deleteAccount() {
      this.errors = {};

      if (!this.password.length) {
        this.errors = {password: ['Password is required']};
        return;
      }

      mainStore.setLoading();
      axios({url: '/self-delete', data: {password: this.password}, method: 'DELETE'})
          .then(async () => {
            mainStore.clearStatus();
            this.password = '';
            await authStore.logout();
            await this.$router.push('/login');
          })
          .catch((err) => {
            this.password = '';
            const data = err.response.data;
            mainStore.clearStatus();
            if (err.response.status === 422) {
              this.errors = data.errors;
              return;
            }
            this.$toast.error('Error');
          });
    },
    verifyCanDelete() {
      mainStore.setLoading();
      axios({url: '/can-self-delete', method: 'GET'})
          .then((resp) => {
            mainStore.clearStatus();
            this.canSelfDelete = resp.data.can_self_delete;
            this.isPaymentMethodOwner = resp.data.is_payment_method_owner;
            this.isLastAdmin = resp.data.is_last_admin;
            this.isLoading = false;
          })
          .catch((err) => {
            mainStore.clearStatus();

            this.$toast.error('Error');
          });
    },
  },
};
</script>
