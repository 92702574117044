import Vue from 'vue';
import Component from 'vue-class-component';

@Component
/**
 * Plan Mixin for methods to return user plan permissions
 */
export default class PasswordStrengthVars extends Vue {
  private sufficientPassword: Boolean = false;

  /**
   * Returns whether the password is of sufficient strength
   */
  get __MIXINsufficientPassword(): Boolean {
    return this.sufficientPassword;
  }

  /**
   * Returns whether a password strength error
   */
  get __MIXINshowPasswordStrengthError(): Boolean {
    return !this.sufficientPassword;
  }

  /**
   * Calculates the orientation of the device.
   * @param {bool} bool
   */
  __MIXINupdatePasswordStrength(bool: boolean): void {
    this.sufficientPassword = bool;
  }
}
